/**
 * Promise Polyfill
 * adapted from A+ Promise implementation
 *
 * @see https://www.promisejs.org/polyfills/promise-6.1.0.js
 * @since 2018-Oct
 */
if (typeof Promise === "undefined") {
    var Promise = (function () {

        // asap library (simplified for browser)
        var asap = (function () {
            var head = {
                task: void 0,
                next: null
            };
            var tail = head;
            var flushing = false;
            var requestFlush = void 0;

            function flush() {
                while (head.next) {
                    head = head.next;
                    var task = head.task;
                    head.task = void 0;
                    try {
                        task();
                    } catch (e) {
                        setTimeout(function () {
                            throw e;
                        }, 0);
                    }
                }
                flushing = false;
            }

            if (typeof setImmediate === "function") {
                if (typeof window !== "undefined") {
                    requestFlush = setImmediate.bind(window, flush);
                } else {
                    requestFlush = function () {
                        setImmediate(flush);
                    };
                }
            } else if (typeof MessageChannel !== "undefined") {
                var channel = new MessageChannel();
                channel.port1.onmessage = flush;
                requestFlush = function () {
                    channel.port2.postMessage(0);
                };
            } else {
                requestFlush = function () {
                    setTimeout(flush, 0);
                };
            }
            return function (task) {
                tail = tail.next = {
                    task: task,
                    next: null
                };
                if (!flushing) {
                    flushing = true;
                    requestFlush();
                }
            }
        })();


// -- ./lib/core.js ---------------------------------------

        function Promise(fn) {
            if (typeof this !== "object") throw new TypeError("Promises must be constructed via new");
            if (typeof fn !== "function") throw new TypeError("not a function");
            var state = null;
            var value = null;
            var deferreds = [];
            var self = this;
            this.then = function (onFulfilled, onRejected) {
                return new self.constructor(function (resolve, reject) {
                    handle(new Handler(onFulfilled, onRejected, resolve, reject));
                });
            };

            function handle(deferred) {
                if (state === null) {
                    deferreds.push(deferred);
                    return;
                }
                asap(function () {
                    var cb = state ? deferred.onFulfilled : deferred.onRejected;
                    if (cb === null) {
                        (state ? deferred.resolve : deferred.reject)(value);
                        return;
                    }
                    var ret;
                    try {
                        ret = cb(value);
                    } catch (e) {
                        deferred.reject(e);
                        return;
                    }
                    deferred.resolve(ret);
                });
            }

            function resolve(newValue) {
                try {
                    if (newValue === self) throw new TypeError("A promise cannot be resolved with itself.");
                    if (newValue && (typeof newValue === "object" || typeof newValue === "function")) {
                        var then = newValue.then;
                        if (typeof then === "function") {
                            doResolve(then.bind(newValue), resolve, reject);
                            return;
                        }
                    }
                    state = true;
                    value = newValue;
                    finale();
                } catch (e) {
                    reject(e);
                }
            }

            function reject(newValue) {
                state = false;
                value = newValue;
                finale();
            }

            function finale() {
                for (var i = 0, len = deferreds.length; i < len; i++) handle(deferreds[i]);
                deferreds = null;
            }

            doResolve(fn, resolve, reject);
        }

        function Handler(onFulfilled, onRejected, resolve, reject) {
            this.onFulfilled = typeof onFulfilled === "function" ? onFulfilled : null;
            this.onRejected = typeof onRejected === "function" ? onRejected : null;
            this.resolve = resolve;
            this.reject = reject;
        }

        function doResolve(fn, onFulfilled, onRejected) {
            var done = false;
            try {
                fn(function (value) {
                    if (done) return;
                    done = true;
                    onFulfilled(value);
                }, function (reason) {
                    if (done) return;
                    done = true;
                    onRejected(reason);
                });
            } catch (ex) {
                if (done) return;
                done = true;
                onRejected(ex);
            }
        }

// -- ./lib/es6-extensions.js -----------------------------

        function ValuePromise(value) {
            this.then = function (onFulfilled) {
                if (typeof onFulfilled !== "function") return this;
                return new Promise(function (resolve, reject) {
                    asap(function () {
                        try {
                            resolve(onFulfilled(value));
                        } catch (ex) {
                            reject(ex);
                        }
                    });
                });
            };
        }

        ValuePromise.prototype = Promise.prototype;
        var TRUE = new ValuePromise(true);
        var FALSE = new ValuePromise(false);
        var NULL = new ValuePromise(null);
        var UNDEFINED = new ValuePromise(undefined);
        var ZERO = new ValuePromise(0);
        var EMPTYSTRING = new ValuePromise("");
        Promise.resolve = function (value) {
            if (value instanceof Promise) return value;
            if (value === null) return NULL;
            if (value === undefined) return UNDEFINED;
            if (value === true) return TRUE;
            if (value === false) return FALSE;
            if (value === 0) return ZERO;
            if (value === "") return EMPTYSTRING;
            if (typeof value === "object" || typeof value === "function") {
                try {
                    var then = value.then;
                    if (typeof then === "function") {
                        return new Promise(then.bind(value));
                    }
                } catch (ex) {
                    return new Promise(function (resolve, reject) {
                        reject(ex);
                    });
                }
            }
            return new ValuePromise(value);
        };
        Promise.all = function (arr) {
            var args = Array.prototype.slice.call(arr);
            return new Promise(function (resolve, reject) {
                if (args.length === 0) return resolve([]);
                var remaining = args.length;

                function res(i, val) {
                    try {
                        if (val && (typeof val === "object" || typeof val === "function")) {
                            var then = val.then;
                            if (typeof then === "function") {
                                then.call(val, function (val) {
                                    res(i, val);
                                }, reject);
                                return;
                            }
                        }
                        args[i] = val;
                        if (--remaining === 0) {
                            resolve(args);
                        }
                    } catch (ex) {
                        reject(ex);
                    }
                }

                for (var i = 0; i < args.length; i++) {
                    res(i, args[i]);
                }
            });
        };
        Promise.reject = function (value) {
            return new Promise(function (resolve, reject) {
                reject(value);
            });
        };
        Promise.race = function (values) {
            return new Promise(function (resolve, reject) {
                values.forEach(function (value) {
                    Promise.resolve(value).then(resolve, reject);
                });
            });
        };
        Promise.prototype["catch"] = function (onRejected) {
            return this.then(null, onRejected);
        };


// -- ./polyfill-done.js ----------------------------------

        if (typeof Promise.prototype.done !== "function") {
            Promise.prototype.done = function (onFulfilled, onRejected) {
                var self = arguments.length ? this.then.apply(this, arguments) : this;
                self.then(null, function (err) {
                    setTimeout(function () {
                        throw err;
                    }, 0);
                });
            };
        }

        return Promise;

    })();
}
